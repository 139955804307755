import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import logo from "./icon-left-font-monochrome-black.svg";
import { useUser, Login } from "./firebase";
import { useBDPFile, useEditableCredits, parseReportDate } from "./bdp-render";
import { FileDrop } from "./FileDrop";
import { ImportValidation } from "./ImportValidation";
import { CreditConfiguration } from "./CreditConfiguration";
import { Calendar } from "./Calendar";
import rawData from "./example.json";

const App = () => {
  const { user, login, isLoggingIn, errorLoggingIn, logout } = useUser();
  const { parsedData, loadFile } = useBDPFile();
  const viewData = parsedData; // || rawData;
  console.log(user);
  const {
    editableCredits,
    resetEditableCredits,
    setConfigurationForCreditAt,
    globalAllIn,
    setGlobalAllInAndRender,
    startRenderDate,
    endRenderDate,
    setRenderDatesAndRender,
  } = useEditableCredits(
    viewData ? viewData.credits : [],
    parseReportDate(viewData && viewData.report.date)
  );
  const tabHeaders = ["Importação", "Validação", "Configuração", "Calendário"];
  const startTab = tabHeaders[0];
  const [selectedTab, setSelectedTab] = useState(startTab);
  const dataIsReady =
    viewData &&
    editableCredits &&
    editableCredits.some((credit) => credit.amounts);
  console.log(user);
  return (
    <AppContainer>
      <TopBar
        left={<Logo logo={logo}></Logo>}
        middle={
          <Tabs
            showTabs={user}
            headers={tabHeaders}
            selected={selectedTab}
            onClick={(value) => setSelectedTab(value)}
          ></Tabs>
        }
        right={
          user && (
            <Username username={user && user.email} logout={logout}></Username>
          )
        }
      ></TopBar>

      <PageContainer>
        {!user && (
          <Login
            disabled={isLoggingIn}
            onSubmit={(credentials) =>
              login(credentials.email, credentials.password)
            }
            error={errorLoggingIn}
          ></Login>
        )}
        {user && (
          <>
            {selectedTab === "Importação" && (
              <FileDrop
                onFileLoad={(file, result) =>
                  loadFile(file, result, (newData) =>
                    resetEditableCredits(
                      newData.credits,
                      parseReportDate(newData.report.date)
                    )
                  )
                }
              ></FileDrop>
            )}
            {!dataIsReady && selectedTab !== "Importação" && <NoFile></NoFile>}
            {dataIsReady && selectedTab === "Validação" && (
              <ImportValidation
                credits={editableCredits || viewData.credits}
                report={viewData.report}
                guaranteeTypes={viewData.guaranteeTypes}
              ></ImportValidation>
            )}
            {dataIsReady && selectedTab === "Configuração" && (
              <CreditConfiguration
                credits={editableCredits || viewData.credits}
                onConfigChange={(offset, config) =>
                  setConfigurationForCreditAt(offset, config)
                }
                globalAllIn={globalAllIn}
                onGlobalAllInChange={setGlobalAllInAndRender}
              ></CreditConfiguration>
            )}
            {dataIsReady && selectedTab === "Calendário" && (
              <Calendar
                credits={editableCredits || viewData.credits}
                startRenderDate={startRenderDate}
                endRenderDate={endRenderDate}
                onRenderDateChange={setRenderDatesAndRender}
              ></Calendar>
            )}
          </>
        )}
      </PageContainer>
    </AppContainer>
  );
};

const AppContainer = ({ children }) => (
  <div className="container-fluid bg-light">{children}</div>
);

const NoFile = () => (
  <div className="d-flex w-100 justify-content-center py-4">
    <div className="alert alert-warning">Nenhum ficheiro importado</div>
  </div>
);

const TopBar = ({ left, middle, right }) => (
  <div className="d-flex w-100 justify-content-between">
    <div>{left}</div>
    <div className="ml-3 flex-fill mr-3">{middle}</div>
    <div>{right}</div>
  </div>
);

const Logo = ({ logo }) =>
  logo && (
    <div className="flex-fill my-1 py-1 px-2 mr-2">
      <img src={logo} alt="logo" style={{ height: "1rem" }}></img>
    </div>
  );

const Username = ({ username, logout }) => (
  <div>
    <span className="small mr-1">{username}</span>
    <button className="btn btn-link btn-sm" onClick={logout}>
      logout
    </button>
  </div>
);

const Tabs = ({ selected, logo, showTabs, headers, onClick }) => {
  const [hoveredOffset, setHoveredOffset] = useState(-1);
  return (
    <>
      <div className="d-flex">
        {showTabs &&
          headers &&
          headers.map((header, hOffset) => (
            <div
              key={hOffset}
              className={`flex-fill text-center  my-1 py-1 px-2 mr-2 rounded clickable ${
                selected === header
                  ? "black-gradient text-white"
                  : hoveredOffset === hOffset
                  ? "black-accent text-white"
                  : "bg-light text-secondary"
              }`}
              onClick={(_) => onClick(header, hOffset)}
              onMouseEnter={(_) => setHoveredOffset(hOffset)}
              onMouseLeave={(_) => setHoveredOffset(-1)}
            >
              {header}
            </div>
          ))}
      </div>
    </>
  );
};

const PageContainer = ({ children }) => (
  <div className="d-flex">{children}</div>
);

export default App;
