import { useState } from "react";
import { parsePDFJS } from "./bdp-parser";
import moment from "moment";

export const useBDPFile = () => {
  const [parsedData, setData] = useState();

  const loadFile = (file, contents, beforeFn) =>
    parsePDFJS(contents).then(({ lines, credits, guaranteeTypes, report }) => {
      const _credits = credits.map(setAmounts);
      const newData = {
        file,
        lines,
        credits: _credits,
        guaranteeTypes,
        report,
      };
      beforeFn && beforeFn(newData);
      setData(newData);
      return newData;
    });

  return {
    parsedData,
    loadFile,
  };
};

export const useEditableCredits = (initalCredits, reportDate) => {
  const [{ startRenderDate, endRenderDate }, setRenderDates] = useState({
    startRenderDate: reportDate,
    endRenderDate: moment(reportDate).add(10, "years").toDate(),
  });
  const [editableCredits, setCredits] = useState(
    loadCredits(initalCredits, startRenderDate, endRenderDate)
  );
  const [globalAllIn, setGlobalAllIn] = useState(undefined);

  const setConfigurationForCreditAt = (creditOffset, config) => {
    const credit = editableCredits[creditOffset];
    const tempCredit = { ...credit, config };
    const tempCredits = [...editableCredits];
    tempCredits[creditOffset] = tempCredit;
    renderCredits(tempCredits, startRenderDate, endRenderDate);
    setCredits(tempCredits);
  };

  const resetEditableCredits = (credits, newStartRenderDate) => {
    const _startDate = newStartRenderDate;
    const _endDate = moment(newStartRenderDate).add(10, "years").toDate();
    setRenderDates({
      startRenderDate: _startDate,
      endRenderDate: _endDate,
    });
    setCredits(loadCredits(credits, _startDate, _endDate));
  };

  const setRenderDatesAndRender = (
    start,
    end = moment(start).add(10, "years").toDate()
  ) => {
    setRenderDates({ startRenderDate: start, endRenderDate: end });
    const tempCredits = [...editableCredits];
    renderCredits(tempCredits, startRenderDate, endRenderDate);
    setCredits(tempCredits);
  };

  const setGlobalAllInAndRender = (allInPercentage) => {
    setGlobalAllIn(allInPercentage);
    const _credits = [...editableCredits];
    _credits.forEach((credit) => (credit.config.interest = allInPercentage));
    renderCredits(_credits, startRenderDate, endRenderDate);
    setCredits(_credits);
  };

  return {
    editableCredits,
    resetEditableCredits,
    globalAllIn,
    setGlobalAllInAndRender,
    setConfigurationForCreditAt,
    startRenderDate,
    endRenderDate,
    setRenderDatesAndRender,
  };
};

const loadCredits = (credits, startRenderDate, endRenderDate) =>
  renderCredits(
    credits
      .map(setAmounts)
      .map(setInitialCreditConfiguration)
      .map((credit, offset) => ({ ...credit, id: offset })),
    startRenderDate,
    endRenderDate
  );

const setAmounts = (credit) => {
  return {
    ...credit,
    amounts: {
      principal: textValueToFloat(credit.principal),
      payment: textValueToFloat(credit.payment),
      default: textValueToFloat(credit.default),
    },
  };
};

const setInitialCreditConfiguration = (credit) => {
  return {
    ...credit,
    config: {
      creditType: isGuarantee(credit)
        ? CreditTypes.Guarantee.description
        : isFactoring(credit)
        ? CreditTypes.Factoring.description
        : isRevolving(credit)
        ? CreditTypes.Revolving.description
        : isMLP(credit)
        ? CreditTypes.MLP.description
        : CreditTypes.None.description,
      startDate: credit.startDate,
      endDate: credit.endDate,
      amortizationFrequency: credit.period,
      principal: isMLP(credit) ? credit.amounts.principal : undefined,
      usage:
        isRevolving(credit) || isFactoring(credit)
          ? credit.amounts.principal
          : undefined,
      interest: 0,
    },
  };
};

export const parseReportDate = (report) => {
  if (!report) return;
  const months = [
    "janeiro",
    "fevereiro",
    "março",
    "abril",
    "maio",
    "junho",
    "julho",
    "agosto",
    "setembro",
    "outubro",
    "novembro",
    "dezembro",
  ];
  const monthIndex = months.indexOf(report.month);
  const monthText = `${monthIndex + 1}`.padStart(2, "0");
  const dateText = `${report.year}-${monthText}-01`;
  const reportDate = new Date(dateText);
  return reportDate;
};

export const isMLP = (credit) =>
  credit &&
  credit.product &&
  moment(credit.endDate).year() !== 9999 &&
  moment(credit.endDate).diff(moment(credit.startDate), "days") > 360 &&
  !credit.product.includes("Avales") &&
  !credit.product.includes("avales") &&
  !credit.product.includes("Cartão de crédito");

export const isRevolving = (credit) =>
  (credit &&
    credit.product &&
    !credit.product.includes("Avales") &&
    !credit.product.includes("avales") &&
    (moment(credit.endDate).year() === 9999 ||
      moment(credit.endDate).diff(moment(credit.startDate), "days") <= 360 ||
      credit.product.includes("conta corrente") ||
      credit.product.includes("Ultrapassagens") ||
      credit.product.includes("descoberto"))) ||
  credit.product.includes("Cartão de crédito");

export const isFactoring = (credit) =>
  credit && credit.product && credit.product.includes("Factoring");

export const isGuarantee = (credit) =>
  credit &&
  credit.product &&
  (credit.product.includes("Avales") || credit.product.includes("avales"));

const textValueToFloat = (text) => {
  const clean = (text) =>
    text && text.replace(/€/g, "").replace(/\s/g, "").replace(/,/g, ".");
  const parse = (text) => parseFloat(text);
  const cleaned = clean(text);
  const value = parse(cleaned);
  // console.log(`'${text}' -> '${cleaned}' -> ${value}`);
  return value;
};

const periodFromString = (text) => {
  switch (text) {
    case "Mensal":
      return 1;
    case "Trimestral":
      return 3;
    case "Semestral":
      return 4;
    case "Anual":
      return 12;
    default:
      console.warn(`'${text}' is not known period`);
  }
};

export const renderCredits = (credits, renderStartDate, renderEndDate) => {
  credits &&
    credits.forEach((credit) => {
      if (isMLP(credit)) {
        // console.info(`MLP: ${credit.product}`);
        credit.rendered = renderConstantMLP({
          principal: credit.amounts.principal,
          allIn: credit.config.interest / 100 || 0,
          period: periodFromString(credit.period) || 3, // every 3 months as default
          startDate: new Date(credit.startDate),
          endDate: new Date(credit.endDate),
          renderStartDate,
          renderEndDate,
        });
      } else if (isRevolving(credit)) {
        // console.info(`Revolving: ${credit.product}`);
        credit.rendered = renderRevolving({
          usage: credit.config.usage,
          allIn: credit.config.interest / 100 || 0,
          startDate: renderStartDate,
          endDate: renderEndDate,
          renderStartDate,
          renderEndDate,
        });
      } else if (isFactoring(credit)) {
        // console.info(`Factoring: ${credit.product}`);
        credit.rendered = renderSimpleFactoring({
          limit: credit.config.limit,
          initialUsage: credit.config.initialUsage || 0, // if not starting from 0
          yearlyRevenues: credit.config.yearlyRevenues,
          vatRate: credit.config.vat / 100 || 0, //
          interestRate: credit.config.interest / 100 || 0, // yearly interest
          comissionRate: credit.config.comission / 100 || 0, // % of invoiced amounts
          antecipationRate: credit.config.antecipation / 100 || 0, // % of total invoice
          dso: credit.config.dso, // in days
          startDate: renderStartDate,
          endDate: renderEndDate,
          renderStartDate,
          renderEndDate,
        });
      } else {
        console.warn(`'${credit.product}' is not known`);
      }
    });
  return credits;
};

export const renderConstantMLP = ({
  principal,
  allIn = 0,
  period,
  startDate,
  endDate,
  renderStartDate,
  renderEndDate,
}) => {
  const allAmortizationDates = paymentSchedule({ startDate, endDate, period });
  const futureAmortizationDates = datesAfterDate(
    allAmortizationDates,
    renderStartDate
  );
  const monthlyInterestRate = allIn / 12;
  const amortization = principal / futureAmortizationDates.length;
  const renderMonths = paymentSchedule({
    startDate: renderStartDate,
    endDate: renderEndDate,
    period: 1,
  });
  const payments = renderMonths.reduce((partial, date, dateOffset) => {
    const previous = dateOffset === 0 ? undefined : partial[dateOffset - 1];
    const dateIsPaymentDate = allAmortizationDates.some((paymentDate) =>
      sameMonthAndYear(paymentDate, date)
    );
    const current =
      dateOffset === 0
        ? {
            date,
            principalBefore: principal,
            amortization: dateIsPaymentDate ? amortization : 0,
            interest: monthlyInterestRate * principal,
            principalAfter: dateIsPaymentDate
              ? principal - amortization
              : principal,
          }
        : {
            date,
            principalBefore: previous.principalAfter,
            amortization: dateIsPaymentDate ? amortization : 0,
            interest: monthlyInterestRate * previous.principalAfter,
            principalAfter: dateIsPaymentDate
              ? previous.principalAfter - amortization
              : previous.principalAfter,
          };
    return [...partial, current];
  }, []);
  return payments;
};

export const renderRampedMLP = ({
  principal,
  allIn = 0,
  period,
  startDate,
  endDate,
  yearRamp,
}) => {
  const dates = paymentSchedule({ startDate, endDate, period });
  const futureDates = datesAfterDate(dates, startDate);
  const monthlyInterestRate = allIn / 12;
  const groupedByYear = groupDatesByYear(futureDates);
  if (!yearRamp || yearRamp.length !== Object.keys(groupedByYear).length)
    throw new Error(
      `amortization ramp needs to be defined for the same number of year as the implied by the start and end dates (${
        yearRamp.length
      } vs ${Object.keys(groupedByYear).length})`
    );
  if (sum(yearRamp) !== 1)
    throw new Error(`Ramp must sum 1 and got ${sum(yearRamp)}`);
  const amortizations = Object.keys(groupedByYear)
    .flatMap((year, yearOffset) => {
      return groupedByYear[year].map(
        (date) =>
          (principal * yearRamp[yearOffset]) / groupedByYear[year].length
      );
    })
    .map((value) => value);
  const payments = futureDates.reduce((partial, date, dateOffset) => {
    const previous = dateOffset === 0 ? undefined : partial[dateOffset - 1];
    const current =
      dateOffset === 0
        ? {
            date,
            principalBefore: principal,
            amortization: amortizations[dateOffset],
            interest: monthlyInterestRate * principal,
            principalAfter: principal - amortizations[dateOffset],
          }
        : {
            date,
            principalBefore: previous.principalAfter,
            amortization: amortizations[dateOffset],
            interest: monthlyInterestRate * previous.principalAfter,
            principalAfter: previous.principalAfter - amortizations[dateOffset],
          };
    return [...partial, current];
  }, []);
  return payments;
};

export const renderSimpleFactoring = ({
  limit, // approved maximum usage
  initialUsage, // if not starting from 0
  yearlyRevenues, // Amount
  vatRate,
  interestRate, // yearly interest
  comissionRate, // % of invoiced amounts
  antecipationRate, // % of total invoice
  dso, // in days
  startDate,
  endDate,
}) => {
  const months = paymentSchedule({
    startDate,
    endDate,
    period: 1,
  });
  const monthlyInvoices = months.map((month) => ({
    amount: yearlyRevenues / 12,
    date: month,
  }));
  return renderFactoring({
    limit, // approved maximum usage
    initialUsage, // if not starting from 0
    monthlyInvoices, // monthly schedule
    vatRate,
    interestRate, // yearly interest
    comissionRate, // % of invoiced amounts
    antecipationRate, // % of total invoice
    dso, // in days
  });
};

export const renderFactoring = ({
  limit, // approved maximum usage
  initialUsage, // if not starting from 0
  monthlyInvoices, // monthly schedule
  vatRate,
  interestRate, // yearly interest
  comissionRate, // % of invoiced amounts
  antecipationRate, // % of total invoice
  dso, // in days
}) => {
  const months = paymentSchedule({
    startDate: monthlyInvoices.map((invoice) => invoice.date)[0],
    endDate: monthlyInvoices.map((invoice) => invoice.date)[
      monthlyInvoices.length - 1
    ],
    period: 1,
  });
  return months.reduce((results, month, monthOffset) => {
    const previous = monthOffset === 0 ? undefined : results[monthOffset - 1];
    const pastInvoices = monthlyInvoices
      .filter((invoice) => isDueMoreThan(dso)(invoice.date, month))
      .filter((invoice) => !invoice.paid);
    pastInvoices.forEach((invoice) => (invoice.paid = true));
    const customerPaidAmount =
      sum(pastInvoices.map((invoice) => invoice.amount)) * (1 + vatRate);
    const finalAmount = customerPaidAmount * (1 - antecipationRate);
    const invoicesForMonth = monthlyInvoices.filter((invoice) =>
      sameMonthAndYear(invoice.date, month)
    );
    const invoicedAmount =
      sum(invoicesForMonth.map((invoice) => invoice.amount)) * (1 + vatRate);
    const previousUsage = monthOffset === 0 ? initialUsage : previous.usage;
    const usage = previousUsage + invoicedAmount - customerPaidAmount;
    const interest = (previousUsage * interestRate) / 12;
    const comission = invoicedAmount * comissionRate;
    const antecipatedAmount = invoicedAmount * antecipationRate;
    const cashflow = antecipatedAmount + finalAmount;
    const result = {
      date: month,
      invoicedAmount,
      usage,
      interest,
      comission,
      antecipatedAmount,
      finalAmount,
      cashflow,
    };
    return [...results, result];
  }, []);
};

export const renderRevolving = ({
  usage, // average usage
  allIn, // yearly interest
  startDate,
  endDate,
}) => {
  const period = 1; // generate interest payment every month
  const months = paymentSchedule({ startDate, endDate, period });
  return months.map((month) => ({
    date: month,
    interest: (usage * allIn) / 12,
    usage,
    principal: usage,
  }));
};

// export const renderOneShot = ({ principal, allIn, startDate, endDate }) => {
//   const period = 1; // generate interest payment every month
//   const months = paymentSchedule({ startDate, endDate, period });
//   return months.map((month, offset) => ({
//     date: offset < months.length - 1 ? month : endDate, // final payment on due date
//     interest: (allIn / 12) * principal,
//     principal: offset < months.length - 1 ? principal : 0,
//     amortization: offset < months.length - 1 ? 0 : principal,
//   }));
// };

const groupDatesByYear = (dates) =>
  dates.reduce(
    (partial, date) => ({
      ...partial,
      [date.getFullYear()]: partial[date.getFullYear()]
        ? [...partial[date.getFullYear()], date]
        : [date],
    }),
    {}
  );

export const sumPerYear = (years, items, dateKey = "date", sumKey) => {
  const sumPerYear = years.map((year) =>
    items
      .filter((item) => sameYear(item[dateKey], new Date(`01-01-${year}`)))
      .reduce((total, item) => total + item[sumKey], 0)
  );
  return sumPerYear;
};

export const yearsInItems = (items) => {
  return [
    ...new Set(
      items.map((item) => item && item.getFullYear()).filter((item) => item)
    ),
  ];
};

const sum = (values) => values.reduce((total, value) => total + value, 0);

const getStartAndEndDate2 = (items, key) =>
  items.reduce(
    (results, item, itemOffset) => {
      const current = key ? item[key] : item;
      if (results.startDate === undefined) results.startDate = current;
      else
        results.startDate =
          current.getTime() < results.startDate.getTime()
            ? current
            : results.startDate;
      if (results.endDate === undefined) results.endDate = current;
      else
        results.endDate =
          current.getTime() > results.endDate.getTime()
            ? current
            : results.endDate;
      return results;
    },
    { startDate: undefined, endDate: undefined }
  );

export const CreditTypes = Object.freeze({
  None: Symbol("Seleccionar"),
  MLP: Symbol("MLP"),
  Factoring: Symbol("Factoring"),
  Revolving: Symbol("Revolving"),
  Guarantee: Symbol("Garantia"),
});

const mapEnum = (_enum, defaultLabel = "None") =>
  Object.keys(_enum).map((item) => ({
    label: _enum[item].description || defaultLabel,
    value: _enum[item].description,
  }));

export const paymentSchedule = ({ startDate, endDate, period }) => {
  // startDate and endDate are Date objects
  // period = 1 | 3 | 6| 12 months
  if (period === undefined)
    throw new Error("Periods is undefined. Needs to be 1,3,6 or 12 months");
  var _dates = [];
  var _lastDate = startDate;
  while (_lastDate <= endDate) {
    let _newDate = moment(_lastDate).add(period, "months").toDate();
    _dates = [..._dates, _newDate];
    _lastDate = _dates[_dates.length - 1];
  }
  return _dates;
};

export const sameYear = (date1, date2) =>
  date1 && date2 && date1.getFullYear() === date2.getFullYear();

export const sameMonthAndYear = (date1, date2) =>
  date1 &&
  date2 &&
  date1.getFullYear() === date2.getFullYear() &&
  date1.getMonth() === date2.getMonth();

const datesAfterDate = (dates, refDate) =>
  dates &&
  refDate &&
  dates.filter((date) => date.getTime() > refDate.getTime());

// const datesAfterNow = (dates) => datesAfterDate(dates, new Date());

const isDueMoreThan = (days) => (pastDate, date) => {
  const delta = moment(pastDate).diff(moment(date), "days");
  return delta < -days;
};
