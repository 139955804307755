import React, { useState } from "react";
import { CreditTypes } from "./bdp-render";

export const CreditConfiguration = ({
  credits,
  onConfigChange,
  globalAllIn,
  onGlobalAllInChange,
}) => {
  const [selectedCreditType, setSelectedCreditType] = useState(
    CreditTypes.None.description
  );
  const filteredCredits = credits.filter((credit) =>
    selectedCreditType === CreditTypes.None.description
      ? true
      : credit.config.creditType === selectedCreditType
  );
  return (
    <PageWrap>
      <TopBar
        credits={filteredCredits}
        onCreditTypeChange={setSelectedCreditType}
        interest={globalAllIn}
        onGlobalAllInChange={onGlobalAllInChange}
      ></TopBar>
      <CreditTable
        credits={filteredCredits}
        onConfigChange={onConfigChange}
      ></CreditTable>
    </PageWrap>
  );
};

const PageWrap = ({ children }) => (
  <div className="d-flex flex-column w-100">{children}</div>
);

const TopBar = ({
  creditType,
  interest,
  credits,
  onCreditTypeChange,
  onGlobalAllInChange,
}) => (
  <div className="d-flex justify-content-between small">
    <div>
      <span className="mr-2">Filtro</span>
      <Select
        options={mapEnum(CreditTypes)}
        selected={creditType}
        onChange={onCreditTypeChange}
      ></Select>
    </div>
    <div>
      <XBadge>
        {formatAmount(
          credits
            .map((credit) => credit.amounts.principal)
            .reduce((total, item) => total + item, 0)
        )}
      </XBadge>
    </div>
    <div>
      <InterestInput
        value={interest || 0}
        onChange={onGlobalAllInChange}
      ></InterestInput>
    </div>
  </div>
);

const CreditTable = ({ credits, onConfigChange, guaranteeTypes }) => (
  <table className="table table-sm table-hover bg-white">
    <thead>
      <tr>
        <th>Credor</th>
        <th>Produto</th>
        <th>Início</th>
        <th>Fim</th>
        <th className="text-right">Dívida</th>
        <th className="black-white secondary-bg">Configuração</th>
      </tr>
    </thead>
    <tbody>
      {credits &&
        credits.map((credit, creditOffset) => (
          <tr key={creditOffset} className="small">
            <td>{trimCompanyName(credit.creditor)}</td>
            <td>
              <span title={credit.product}>{trimTrail(credit.product)}</span>
            </td>
            <td>
              <small>{credit.startDate}</small>
            </td>
            <td>
              <small>{credit.endDate}</small>
            </td>
            <td className="text-right">
              {formatAmount(credit.amounts.principal)}
            </td>
            <td className="secondary-bg">
              <Configuration
                {...credit.config}
                onConfigurationChange={onConfigChange.bind(this, credit.id)}
              ></Configuration>
            </td>
          </tr>
        ))}
    </tbody>
  </table>
);

const Configuration = ({
  creditType,
  amortizationFrequency,
  usage,
  invoice,
  interest,
  onConfigurationChange,
}) => {
  // console.log({
  //   creditType,
  //   amortizationFrequency,
  //   usage,
  //   invoice,
  //   interest,
  // });
  return (
    <>
      <Select
        options={mapEnum(CreditTypes)}
        selected={creditType}
        onChange={(creditType) =>
          onConfigurationChange({
            creditType,
            amortizationFrequency,
            usage,
            invoice,
            interest,
          })
        }
      ></Select>
      {creditType === "MLP" && (
        <>
          <HSpace></HSpace>
          <Select
            options={mapEnum(AmortizationFrequency)}
            selected={amortizationFrequency}
            onChange={(amortizationFrequency) =>
              onConfigurationChange({
                creditType,
                amortizationFrequency,
                usage,
                invoice,
                interest,
              })
            }
          ></Select>
          <HSpace></HSpace>
          <InterestInput
            value={interest}
            onChange={(interest) =>
              onConfigurationChange({
                creditType,
                amortizationFrequency,
                usage,
                invoice,
                interest,
              })
            }
          ></InterestInput>
        </>
      )}
      {creditType === "Factoring" && (
        <>
          <HSpace></HSpace>
          <UsageInput
            value={usage}
            onChange={(usage) =>
              onConfigurationChange({
                creditType,
                amortizationFrequency,
                usage,
                invoice,
                interest,
              })
            }
          ></UsageInput>
          <HSpace></HSpace>
          <InvoiceInput
            value={invoice}
            onChange={(invoice) =>
              onConfigurationChange({
                creditType,
                amortizationFrequency,
                usage,
                invoice,
                interest,
              })
            }
          ></InvoiceInput>
          <HSpace></HSpace>
          <InterestInput
            value={interest}
            onChange={(interest) =>
              onConfigurationChange({
                creditType,
                amortizationFrequency,
                usage,
                invoice,
                interest,
              })
            }
          ></InterestInput>
        </>
      )}
      {creditType === CreditTypes.Revolving.description && (
        <>
          <HSpace></HSpace>
          <UsageInput
            value={usage}
            onChange={(usage) =>
              onConfigurationChange({
                creditType,
                amortizationFrequency,
                usage,
                invoice,
                interest,
              })
            }
          ></UsageInput>
          <HSpace></HSpace>
          <InterestInput
            value={interest}
            onChange={(interest) =>
              onConfigurationChange({
                creditType,
                amortizationFrequency,
                usage,
                invoice,
                interest,
              })
            }
          ></InterestInput>
        </>
      )}
    </>
  );
};

const AmortizationFrequency = Object.freeze({
  Annual: Symbol("Anual"),
  Semestre: Symbol("Semestral"),
  Quarter: Symbol("Trimestral"),
  Monthly: Symbol("Mensal"),
});

const mapEnum = (_enum, defaultLabel = "None") =>
  Object.keys(_enum).map((item) => ({
    label: _enum[item].description || defaultLabel,
    value: _enum[item].description,
  }));

const Badge = ({ type, children }) => (
  <span className={`badge badge-${type || "secondary"}`}>{children}</span>
);

const XBadge = ({ type, children }) => (
  <span className={`badge black-accent text-white`}>{children}</span>
);

const HSpace = (_) => <span className="mx-2"></span>;

const Disclose = ({ header, children }) => {
  const [on, set] = useState(false);
  return (
    <div>
      <div onClick={(_) => set(!on)}>{header}</div>
      {on && children}
    </div>
  );
};

const Select = ({ options = [], selected = undefined, onChange }) => (
  <select value={selected} onChange={(event) => onChange(event.target.value)}>
    {options.map((option, offset) => (
      <option key={offset} value={option.value || option.label || option}>
        {option.label || option.value || option}
      </option>
    ))}
  </select>
);

const InterestInput = ({ value, onChange }) => (
  <>
    <small>All In</small> &nbsp;
    <input
      type="text"
      maxLength={3}
      style={{ width: "3rem", textAlign: "right" }}
      value={value}
      onChange={(event) => onChange(parseFloat(event.target.value))}
    />
    &nbsp; %
  </>
);

const InvoiceInput = ({ value, onChange }) => (
  <>
    <small>Facturação</small> &nbsp;
    <input
      type="text"
      maxLength={12}
      style={{ width: "6rem", textAlign: "right" }}
      value={value}
      onChange={(event) => onChange(event.target.value)}
    />
    &nbsp; €
  </>
);
const UsageInput = ({ value, onChange }) => (
  <>
    <small>Utilização</small> &nbsp;
    <input
      type="text"
      maxLength={12}
      style={{ width: "6rem", textAlign: "right" }}
      value={value}
      onChange={(event) => onChange(event.target.value)}
    />
    &nbsp;€
  </>
);

const trimCompanyName = (text) => {
  const terminals = ["-", ","];
  const indexes = terminals.map((terminal) => text.indexOf(terminal));
  const minIndex = indexes.reduce(
    (partial, index) => (index > -1 ? Math.min(partial, index) : partial),
    text.length
  );
  return text.substring(0, minIndex);
};

const groupByCreditorAndProducts = (credits) => {
  const uniqueCreditors = uniqueItems(credits.map(pluck("creditor")));
  return uniqueCreditors.map((creditor) => ({
    creditor,
    products: groupByProducts(
      credits.filter((credit) => credit.creditor === creditor)
    ),
  }));
};

const groupByProducts = (credits) => {
  const uniqueProducts = uniqueItems(credits.map(pluck("product")));
  return uniqueProducts.map((product) => ({
    product,
    credits: credits.filter((credit) => credit.product === product),
  }));
};

const uniqueItems = (array) => [...new Set(array)];

const pluck = (key) => (obj) => obj && obj[key];

const trimTrail = (text) =>
  text.length > 30 ? text.substring(0, 25) + " ..." : text;

const formatAmount = (amount) =>
  new Intl.NumberFormat("pt-PT", {
    style: "currency",
    currency: "EUR",
  }).format(amount);
