import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { paymentSchedule, yearsInItems, CreditTypes } from "./bdp-render";

import pt from "date-fns/locale/pt";
registerLocale("pt", pt);

export const Calendar = ({
  credits,
  startRenderDate,
  endRenderDate,
  onRenderDateChange,
}) => {
  const [viewMode, setViewMode] = useState(ViewMode.Nano.description);
  const [dateMode, setDateMode] = useState(DateMode.Years.description);
  const [{ sliceStartDate, sliceEndDate }, setSliceDateRange] = useState({
    sliceStartDate: startRenderDate,
    sliceEndDate: endRenderDate,
  });
  const { timeLabels, labelHeader, labels, dataRows } = dataForViewMode(
    credits,
    viewMode,
    dateMode,
    sliceStartDate,
    sliceEndDate
  );
  return (
    <PageWrap>
      <div>
        <Select
          options={mapEnum(ViewMode)}
          selected={viewMode}
          onChange={setViewMode}
        ></Select>
        &nbsp;
        <Select
          options={mapEnum(DateMode)}
          selected={dateMode}
          onChange={setDateMode}
        ></Select>
      </div>

      <TableWrap>
        <TimeTable
          timeLabels={timeLabels}
          labelHeader={labelHeader}
          labels={labels}
          dataRows={dataRows}
        ></TimeTable>
      </TableWrap>
    </PageWrap>
  );
};

/** 
<small>
          Início
          <DatePicker
            locale="pt"
            dateFormat="d MMMM yyyy"
            selected={sliceStartDate}
            popperModifiers={{
              offset: {
                enabled: true,
                offset: "5px, 10px",
              },
              preventOverflow: {
                enabled: true,
                escapeWithReference: false,
                boundariesElement: "viewport",
              },
            }}
            onChange={(date) =>
              setSliceDateRange({ sliceStartDate: date, sliceEndDate })
            }
          ></DatePicker>
        </small>
        <small>
          Fim
          <DatePicker
            locale="pt"
            dateFormat="d MMMM yyyy"
            selected={sliceEndDate}
            popperModifiers={{
              offset: {
                enabled: true,
                offset: "5px, 10px",
              },
              preventOverflow: {
                enabled: true,
                escapeWithReference: false,
                boundariesElement: "viewport",
              },
            }}
            onChange={(date) =>
              setSliceDateRange({ sliceStartDate, sliceEndDate: date })
            }
          ></DatePicker>
        </small>
*/
const TopBar = ({ children }) => (
  <div className="d-flex justify-content-between">
    {children.map((child, offset) => (
      <div key={offset} className={`mr-2 ${offset === 0 ? "flex-fill" : ""}`}>
        {child}
      </div>
    ))}
  </div>
);

const dataForViewMode = (
  credits,
  viewMode,
  dateMode,
  startRenderDate,
  endRenderDate
) => {
  let timeLabels = [],
    labelHeader = "",
    labels = [],
    rawDataRows = [[]],
    dataRows = [[]];

  const months = paymentSchedule({
    startDate: startRenderDate,
    endDate: endRenderDate,
    period: 1,
  });

  const allRenderedCredits = credits.filter((credit) => credit.rendered);
  const MLPcreditsRendered = credits
    .filter(
      (credit) => credit.config.creditType === CreditTypes.MLP.description
    )
    .filter((credit) => credit.rendered);
  const revolvingCreditsRendered = credits
    .filter(
      (credit) => credit.config.creditType === CreditTypes.Revolving.description
    )
    .filter((credit) => credit.rendered);
  const factoringCreditsRendered = credits
    .filter(
      (credit) => credit.config.creditType === CreditTypes.Factoring.description
    )
    .filter((credit) => credit.rendered);

  switch (viewMode) {
    case ViewMode.Nano.description:
      labels = ["Amortizações", "Juros", "Comissões", "Total"];
      rawDataRows = [
        MLPcreditsRendered.map((credit) =>
          credit.rendered.map((item) => ({
            credit,
            date: item.date,
            amount: item.amortization || 0,
          }))
        ),
        allRenderedCredits.map((credit) =>
          credit.rendered.map((item) => ({
            credit,
            date: item.date,
            amount: item.interest || 0,
          }))
        ),
        allRenderedCredits.map((credit) =>
          credit.rendered.map((item) => ({
            credit,
            date: item.date,
            amount: item.comission || 0,
          }))
        ),
        allRenderedCredits.map((credit) =>
          credit.rendered.map((item) => ({
            credit,
            date: item.date,
            amount:
              item.amortization || 0 + item.interest || 0 + item.comission || 0,
          }))
        ),
      ];
      break;
    case ViewMode.Mini.description:
      labels = [
        "MLP",
        "Amortizações",
        "Juros",
        "Comissões",
        "Revolving",
        "Amortizações",
        "Juros",
        "Comissões",
        "Factoring",
        "Amortizações",
        "Juros",
        "Comissões",
        "Total",
      ];
      const MLPData = MLPcreditsRendered.map((credit) => ({
        amortizations: credit.rendered.map((item) => ({
          credit,
          date: item.date,
          amount: item.amortization || 0,
        })),
        interest: credit.rendered.map((item) => ({
          credit,
          date: item.date,
          amount: item.interest || 0,
        })),
        comission: credit.rendered.map((item) => ({
          credit,
          date: item.date,
          amount: item.comission || 0,
        })),
      }));
      const revData = revolvingCreditsRendered.map((credit) => ({
        amortizations: credit.rendered.map((item) => ({
          credit,
          date: item.date,
          amount: item.amortization || 0,
        })),
        interest: credit.rendered.map((item) => ({
          credit,
          date: item.date,
          amount: item.interest || 0,
        })),
        comission: credit.rendered.map((item) => ({
          credit,
          date: item.date,
          amount: item.comission || 0,
        })),
      }));
      const factData = factoringCreditsRendered.map((credit) => ({
        amortizations: credit.rendered.map((item) => ({
          credit,
          date: item.date,
          amount: item.amortization || 0,
        })),
        interest: credit.rendered.map((item) => ({
          credit,
          date: item.date,
          amount: item.interest || 0,
        })),
        comission: credit.rendered.map((item) => ({
          credit,
          date: item.date,
          amount: item.comission || 0,
        })),
      }));

      rawDataRows = [
        [MLPData.amortizations, MLPData.interest, MLPData.comission].flatMap(
          (item) => item
        ),
        MLPData.amortizations,
        MLPData.interest,
        MLPData.comission,
        [revData.amortizations, revData.interest, revData.comission].flatMap(
          (item) => item
        ),
        revData.amortizations,
        revData.interest,
        revData.comission,
        [factData.amortizations, factData.interest, factData.comission].flatMap(
          (item) => item
        ),
        factData.amortizations,
        factData.interest,
        factData.comission,
        [
          MLPData.amortizations,
          MLPData.interest,
          MLPData.comission,
          revData.amortizations,
          revData.interest,
          revData.comission,
          factData.amortizations,
          factData.interest,
          factData.comission,
        ].flatMap((item) => item),
      ];
      break;
    default:
      rawDataRows = [];
      break;
  }

  switch (dateMode) {
    case DateMode.Years.description:
      const years = yearsInItems(months);
      timeLabels = years;
      dataRows = rawDataRows.map(
        (dataRow) =>
          dataRow &&
          sumArrays(
            ...dataRow.map((dataRowItem) =>
              years.map(
                (year) =>
                  dataRowItem &&
                  dataRowItem
                    .filter((item) => item.date.getFullYear() === year)
                    .reduce((total, item) => total + item.amount, 0)
              )
            )
          )
      );
      break;
    case DateMode.Months.description:
      timeLabels = months.map(formatMonthLabel);
      dataRows = rawDataRows.map((dataRow) => {
        return sumArrays(
          ...dataRow.map((dataRowItem) =>
            dataRowItem.map((item) => item.amount)
          )
        );
      });
      break;
    default:
      break;
  }
  dataRows = dataRows.map((row) => row && row.map(formatAmount));
  return { timeLabels, labelHeader, labels, dataRows };
};

const formatAmount = (amount) =>
  new Intl.NumberFormat("pt-PT", {
    style: "currency",
    currency: "EUR",
  }).format(amount);

const monthLabels = [
  "Jan",
  "Fev",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "Ago",
  "Set",
  "Out",
  "Nov",
  "Dez",
];

const formatMonthLabel = (date) =>
  `${monthLabels[date.getMonth()]}'${String(date.getFullYear()).slice(2)}`;

const sumArrays = (...arrays) => {
  const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
  const result = Array.from({ length: n });
  return result.map((_, i) =>
    arrays.map((xs) => xs[i] || 0).reduce((sum, x) => sum + x, 0)
  );
};

export const ViewMode = Object.freeze({
  Nano: Symbol("Resumo"),
  Mini: Symbol("Por Tipo"),
  Normal: Symbol("Por IC"),
  Large: Symbol("Crédito a Crédito"),
});

export const DateMode = Object.freeze({
  Years: Symbol("Anos"),
  Months: Symbol("Meses"),
});

const mapEnum = (_enum, defaultLabel = "None") =>
  Object.keys(_enum).map((item) => ({
    label: _enum[item].description || defaultLabel,
    value: _enum[item].description,
  }));

const PageWrap = ({ children }) => <div className="w-100">{children}</div>;
const TableWrap = ({ children }) => (
  <div className="overflow-auto">{children}</div>
);

const Select = ({ options = [], selected = undefined, onChange }) => (
  <select value={selected} onChange={(event) => onChange(event.target.value)}>
    {options.map((option, offset) => (
      <option key={offset} value={option.value || option.label || option}>
        {option.label || option.value || option}
      </option>
    ))}
  </select>
);

export const TimeTable = ({
  labels,
  dataRows = [[]],
  timeLabels,
  labelHeader = "",
}) => (
  <table className="table bg-white">
    <thead>
      <tr>
        <th style={{ width: "10rem" }}>
          <small>{labelHeader}</small>
        </th>
        {timeLabels &&
          timeLabels.map((timeLabel, offset) => (
            <th key={offset} className="text-right">
              <small>{timeLabel}</small>
            </th>
          ))}
      </tr>
    </thead>
    <tbody>
      {labels &&
        labels.map((label, labelOffset) => (
          <tr key={labelOffset}>
            <td style={{ width: "10rem" }}>{label}</td>
            {dataRows &&
              dataRows[labelOffset] &&
              dataRows[labelOffset].map((data, dataOffset) => (
                <td key={dataOffset} className="text-right">
                  <small>{data}</small>
                </td>
              ))}
          </tr>
        ))}
    </tbody>
  </table>
);

export const RenderTable = ({ creditRenders, dates }) => (
  <table className="table table-sm table-hover bg-white">
    <thead>
      <tr>
        <th>Credor</th>
        <th>Produto</th>
        {dates &&
          dates.map((date, dateOffset) => (
            <th key={dateOffset} className="text-right">
              <small>{date}</small>
            </th>
          ))}
      </tr>
    </thead>
    <tbody>
      {creditRenders &&
        creditRenders.map((credit, creditOffset) => (
          <tr key={creditOffset} className="small">
            <td>{trimCompanyName(credit.creditor)}</td>
            <td>
              <span title={credit.product}>{trimTrail(credit.product)}</span>
            </td>
            <td className="text-right">{credit.amortization}</td>
          </tr>
        ))}
    </tbody>
  </table>
);

const trimCompanyName = (text) => {
  const terminals = ["-", ","];
  const indexes = terminals.map((terminal) => text.indexOf(terminal));
  const minIndex = indexes.reduce(
    (partial, index) => (index > -1 ? Math.min(partial, index) : partial),
    text.length
  );
  return text.substring(0, minIndex);
};

const trimTrail = (text) =>
  text.length > 30 ? text.substring(0, 25) + " ..." : text;
