import React, { useState } from "react";

export const ImportValidation = ({ credits, guaranteeTypes, report }) => {
  console.log({ credits });
  const [viewMode, setViewMode] = useState(GroupModes.Credits.description);
  const { filtered, filterByCreditor, reset } = useFilter(credits);
  const creditsByCreditorAndProducts = groupByCreditorAndProducts(credits);
  const creditsByCreditorAndCreditTypes = groupByCreditorAndCreditTypes(
    credits
  );
  // const creditsByProductsAndCreditors = groupByProductsAndCreditors(credits);
  const creditsByConfigCreditType = groupByConfigProduct(credits);
  return (
    <div
      className="py-2 w-100
    "
    >
      <ReportValidation
        report={report}
        credits={credits}
        viewMode={viewMode}
        onViewModeChange={(value) => setViewMode(value)}
        filtered={filtered}
        onFilter={(value) =>
          value === "Todos" ? reset() : filterByCreditor(value)
        }
      ></ReportValidation>
      {viewMode === GroupModes.Credits.description && (
        <CreditTable
          credits={filtered}
          guaranteeTypes={guaranteeTypes}
        ></CreditTable>
      )}
      {viewMode === GroupModes.Creditors.description && (
        <CreditorTable
          creditors={creditsByCreditorAndProducts}
          guaranteeTypes={guaranteeTypes}
        ></CreditorTable>
      )}
      {viewMode === GroupModes.CreditorsByType.description && (
        <CreditorCreditTypesTable
          creditors={creditsByCreditorAndCreditTypes}
          guaranteeTypes={guaranteeTypes}
        ></CreditorCreditTypesTable>
      )}
      {viewMode === GroupModes.Products.description && (
        <ProductTable
          creditsByCreditType={creditsByConfigCreditType}
          guaranteeTypes={guaranteeTypes}
        ></ProductTable>
      )}
    </div>
  );
};

const useFilter = (credits) => {
  const [filtered, setFiltered] = useState(credits);
  const reset = (_) => setFiltered(credits);
  const filterByCreditor = (value) =>
    setFiltered(
      credits.filter((credit) => trimCompanyName(credit.creditor) === value)
    );
  return { filtered, filterByCreditor, reset };
};

const ReportValidation = ({
  report,
  credits,
  viewMode,
  onViewModeChange,
  filtered,
  onFilter,
}) => (
  <div className="d-flex pt-2 pb-4 px-2">
    <div className="flex-fill">
      {trimCompanyName(report.company.name)}
      <XBadge>{report.company.vatId}</XBadge>
    </div>
    <div className="flex-fill">
      Data{" "}
      <XBadge>
        {report.date.month} {report.date.year}
      </XBadge>
    </div>
    <div className="flex-fill text-center">
      Instituições <XBadge>{report.institutions}</XBadge>
    </div>
    <div className="flex-fill text-right">
      Créditos{" "}
      <XBadge>
        {" "}
        {filtered.length} / {report.credits}{" "}
      </XBadge>
    </div>
    <div className="flex-fill text-right">
      Total{" "}
      <XBadge>
        {formatAmount(
          filtered
            .map((item) => item.amounts)
            .map((amount) => amount.principal)
            .reduce((total, item) => total + item, 0)
        )}
      </XBadge>
    </div>
    <div className="flex-fill text-right">
      Incumprimentos{" "}
      <Badge>
        {formatAmount(
          filtered
            .map((item) => item.amounts)
            .map((amount) => amount.default)
            .reduce((total, item) => total + item, 0)
        )}
      </Badge>
    </div>
    <div className="flex-fill text-right">
      <Select
        options={mapEnum(GroupModes)}
        selected={viewMode}
        onChange={onViewModeChange}
      ></Select>
    </div>
    {/* {viewMode === GroupModes.Credits.description && (
      <div className="flex-fill text-right">
        <Select
          options={[
            { value: undefined, label: "Todos" },
            ...uniqueItems(credits.map(pluck("creditor")).map(trimCompanyName)),
          ]}
          onChange={onFilter}
        ></Select>
      </div>
    )} */}
  </div>
);

const GroupModes = Object.freeze({
  Credits: Symbol("Créditos"),
  Creditors: Symbol("Credores e Produtos"),
  CreditorsByType: Symbol("Credores e Tipos"),
  Products: Symbol("Tipos e Credores"),
});

const mapEnum = (_enum, defaultLabel = "None") =>
  Object.keys(_enum).map((item) => ({
    label: _enum[item].description || defaultLabel,
    value: _enum[item].description,
  }));

const formatAmount = (amount) =>
  new Intl.NumberFormat("pt-PT", {
    style: "currency",
    currency: "EUR",
  }).format(amount);

const Badge = ({ type, children }) => (
  <span className={`badge badge-${type || "secondary"}`}>{children}</span>
);

const XBadge = ({ type, children }) => (
  <span className={`badge black-accent text-white`}>{children}</span>
);

const CreditTable = ({ credits, guaranteeTypes }) => (
  <table className="table table-sm table-hover bg-white">
    <thead>
      <tr>
        <th>Credor</th>
        <th>Produto</th>
        <th>Início</th>
        <th>Fim</th>
        <th className="text-right">Dívida</th>
        <th className="text-right">Incumprimento</th>
        <th className="text-right">Garantia</th>
      </tr>
    </thead>
    <tbody>
      {credits &&
        credits.map((credit, creditOffset) => (
          <tr key={creditOffset} className="small">
            <td>{trimCompanyName(credit.creditor)}</td>
            <td>
              <span title={credit.product}>{trimTrail(credit.product)}</span>
            </td>
            <td>{credit.startDate}</td>
            <td>{credit.endDate}</td>
            <td className="text-right">{credit.principal}</td>
            <td className="text-right">{credit.default}</td>
            <td>
              {credit.guarantees.map((guarantee, guaranteeOffset) => (
                <div key={guaranteeOffset} className="text-right">
                  {guaranteeDescription(guarantee, guaranteeTypes)}
                </div>
              ))}
            </td>
          </tr>
        ))}
    </tbody>
  </table>
);

const CreditorTable = ({ creditors, guaranteeTypes }) => (
  <table className="table table-sm table-hover bg-white">
    <thead>
      <tr>
        <th>Credor</th>
        <th>Produtos</th>
        <th className="text-right">Dívida</th>
        <th className="text-right">Incumprimentos</th>
        <th className="text-right">Garantia</th>
      </tr>
    </thead>
    <tbody>
      {creditors &&
        creditors.map((creditor, creditOffset) =>
          creditor.products.map((product, productOffset) => (
            <tr key={creditOffset + "-" + productOffset} className="small">
              {productOffset === 0 && (
                <td rowSpan={creditor.products.length}>
                  {trimCompanyName(creditor.creditor)}
                  &nbsp;
                  <XBadge>
                    {formatAmount(
                      creditor.products
                        .flatMap(pluck("credits"))
                        .map(pluck("amounts"))
                        .map(pluck("principal"))
                        .reduce((total, item) => total + item, 0)
                    )}
                  </XBadge>
                  &nbsp;
                  <Badge>
                    {formatAmount(
                      creditor.products
                        .flatMap(pluck("credits"))
                        .map(pluck("amounts"))
                        .map(pluck("default"))
                        .reduce((total, item) => total + item, 0)
                    )}
                  </Badge>
                </td>
              )}
              <td>
                <span>
                  {product.product} <XBadge>{product.credits.length}</XBadge>{" "}
                  <Badge>
                    {
                      product.credits.filter(
                        (credit) => credit.amounts.default > 0
                      ).length
                    }
                  </Badge>
                </span>
              </td>
              <td className="text-right">
                {formatAmount(
                  product.credits
                    .map(pluck("amounts"))
                    .map(pluck("principal"))
                    .reduce((total, item) => total + item, 0)
                )}
              </td>
              <td className="text-right">
                {formatAmount(
                  product.credits
                    .map(pluck("amounts"))
                    .map(pluck("default"))
                    .reduce((total, item) => total + item, 0)
                )}
              </td>
              <td>
                <Disclose
                  header={
                    <div className="text-right text-primary">
                      {product.credits.flatMap(pluck("guarantees")).length}
                      &nbsp;itens
                    </div>
                  }
                >
                  {uniqueItems(
                    product.credits
                      .flatMap(pluck("guarantees"))
                      .map((guarantee) =>
                        guaranteeDescription(guarantee, guaranteeTypes)
                      )
                  ).map((guaranteeDescription, guaranteeOffset) => (
                    <div key={guaranteeOffset} className="text-right">
                      {guaranteeDescription}
                    </div>
                  ))}
                </Disclose>
              </td>
            </tr>
          ))
        )}
    </tbody>
  </table>
);

const CreditorCreditTypesTable = ({ creditors, guaranteeTypes }) => (
  <table className="table table-sm table-hover bg-white">
    <thead>
      <tr>
        <th>Credor</th>
        <th>Tipos</th>
        <th className="text-right">Dívida</th>
        <th className="text-right">Incumprimentos</th>
        <th className="text-right">Garantia</th>
      </tr>
    </thead>
    <tbody>
      {creditors &&
        creditors.map((creditor, creditOffset) =>
          creditor.creditTypes.map((creditType, crediTypeOffset) => (
            <tr key={creditOffset + "-" + crediTypeOffset} className="small">
              {crediTypeOffset === 0 && (
                <td rowSpan={creditor.creditTypes.length}>
                  {trimCompanyName(creditor.creditor)}
                  &nbsp;
                  <XBadge>
                    {formatAmount(
                      creditor.creditTypes
                        .flatMap(pluck("credits"))
                        .map(pluck("amounts"))
                        .map(pluck("principal"))
                        .reduce((total, item) => total + item, 0)
                    )}
                  </XBadge>
                  &nbsp;
                  <Badge>
                    {formatAmount(
                      creditor.creditTypes
                        .flatMap(pluck("credits"))
                        .map(pluck("amounts"))
                        .map(pluck("default"))
                        .reduce((total, item) => total + item, 0)
                    )}
                  </Badge>
                </td>
              )}
              <td>
                <span>
                  {creditType.creditType}{" "}
                  <XBadge>{creditType.credits.length}</XBadge>
                  &nbsp;
                  <Badge>
                    {
                      creditType.credits.filter(
                        (credit) => credit.amounts.default > 0
                      ).length
                    }
                  </Badge>
                </span>
              </td>
              <td className="text-right">
                {formatAmount(
                  creditType.credits
                    .map(pluck("amounts"))
                    .map(pluck("principal"))
                    .reduce((total, item) => total + item, 0)
                )}
              </td>
              <td className="text-right">
                {formatAmount(
                  creditType.credits
                    .map(pluck("amounts"))
                    .map(pluck("default"))
                    .reduce((total, item) => total + item, 0)
                )}
              </td>
              <td>
                <Disclose
                  header={
                    <div className="text-right text-primary">
                      {creditType.credits.flatMap(pluck("guarantees")).length}
                      &nbsp;itens
                    </div>
                  }
                >
                  {uniqueItems(
                    creditType.credits
                      .flatMap(pluck("guarantees"))
                      .map((guarantee) =>
                        guaranteeDescription(guarantee, guaranteeTypes)
                      )
                  ).map((guaranteeDescription, guaranteeOffset) => (
                    <div key={guaranteeOffset} className="text-right">
                      {guaranteeDescription}
                    </div>
                  ))}
                </Disclose>
              </td>
            </tr>
          ))
        )}
    </tbody>
  </table>
);

const ProductTable = ({ creditsByCreditType, guaranteeTypes }) => (
  <table className="table table-sm table-hover bg-white">
    <thead>
      <tr>
        <th>Tipo</th>
        <th>Credores</th>
        <th className="text-right">Dívida</th>
        <th className="text-right">Incumprimento</th>
        <th className="text-right">Dívida Total</th>
        <th className="text-right">Incumprimento Total</th>
      </tr>
    </thead>
    <tbody>
      {creditsByCreditType.map(({ creditType, credits }, offset) => (
        <tr key={offset} className="small">
          <td>{creditType}</td>
          <td>
            {uniqueItems(credits.map((credit) => credit.creditor)).map(
              (creditor, offset) => (
                <div key={offset}>{creditor}</div>
              )
            )}
          </td>
          <td className="text-right">
            {uniqueItems(credits.map((credit) => credit.creditor)).map(
              (creditor, offset) => (
                <div key={offset}>
                  {formatAmount(
                    credits
                      .filter((credit) => credit.creditor === creditor)
                      .map((credit) => credit.amounts)
                      .map((amounts) => amounts.principal)
                      .reduce((total, item) => total + item, 0)
                  )}
                </div>
              )
            )}
          </td>
          <td className="text-right">
            {uniqueItems(credits.map((credit) => credit.creditor)).map(
              (creditor, offset) => (
                <div key={offset}>
                  {formatAmount(
                    credits
                      .filter((credit) => credit.creditor === creditor)
                      .map((credit) => credit.amounts)
                      .map((amounts) => amounts.default)
                      .reduce((total, item) => total + item, 0)
                  )}
                </div>
              )
            )}
          </td>
          <td className="text-right">
            {formatAmount(
              credits
                .map((credit) => credit.amounts)
                .map((amounts) => amounts.principal)
                .reduce((total, item) => total + item, 0)
            )}
          </td>
          <td className="text-right">
            {formatAmount(
              credits
                .map((credit) => credit.amounts)
                .map((amounts) => amounts.default)
                .reduce((total, item) => total + item, 0)
            )}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

const trimCompanyName = (text) => {
  const terminals = ["-", ","];
  const indexes = terminals.map((terminal) => text.indexOf(terminal));
  const minIndex = indexes.reduce(
    (partial, index) => (index > -1 ? Math.min(partial, index) : partial),
    text.length
  );
  return text.substring(0, minIndex);
};

const guaranteeDescription = (guarantee, guaranteeTypes) =>
  `${trimTrail(guaranteeTypes[guarantee.type])} (${guarantee.amount})`;

const trimTrail = (text) =>
  text && text.length > 30 ? text.substring(0, 25) + " ..." : text;

const uniqueItems = (array) => [...new Set(array)];

const Select = ({ options = [], selected = undefined, onChange }) => (
  <select value={selected} onChange={(event) => onChange(event.target.value)}>
    {options.map((option, offset) => (
      <option key={offset} value={option.value || option.label || option}>
        {option.label || option.value || option}
      </option>
    ))}
  </select>
);

const addTextAmounts = (textAmounts) => {
  return textAmounts
    .map((textAmount) =>
      textAmount.replace(" €", "").replace(" ", "").replace(",", ".")
    )
    .map((textAmount) => parseFloat(textAmount))
    .reduce((total, value) => total + value, 0);
};

const groupByCreditor = (credits) => {
  const uniqueCreditors = uniqueItems(credits.map(pluck("creditor")));
  return uniqueCreditors.map((creditor) => ({
    creditor,
    credits: credits.filter((credit) => credit.creditor === creditor),
  }));
};

const groupByProducts = (credits) => {
  const uniqueProducts = uniqueItems(credits.map(pluck("product")));
  return uniqueProducts.map((product) => ({
    product,
    credits: credits.filter((credit) => credit.product === product),
  }));
};

const groupByCreditorAndProducts = (credits) => {
  const uniqueCreditors = uniqueItems(credits.map(pluck("creditor")));
  return uniqueCreditors.map((creditor) => ({
    creditor,
    products: groupByProducts(
      credits.filter((credit) => credit.creditor === creditor)
    ),
  }));
};

const groupByCreditorAndCreditTypes = (credits) => {
  const uniqueCreditors = uniqueItems(credits.map(pluck("creditor")));
  return uniqueCreditors.map((creditor) => ({
    creditor,
    creditTypes: groupByConfigProduct(
      credits.filter((credit) => credit.creditor === creditor)
    ),
  }));
};

const groupByProductsAndCreditors = (credits) => {
  const uniqueProducts = uniqueItems(credits.map(pluck("product")));
  return uniqueProducts.map((product) => ({
    product,
    creditors: groupByCreditor(
      credits.filter((credit) => credit.product === product)
    ),
  }));
};

const groupByConfigProduct = (credits) => {
  const uniqueConfigProducts = uniqueItems(
    credits.map(pluck("config")).map(pluck("creditType"))
  );
  return uniqueConfigProducts.map((groupCreditType) => ({
    creditType: groupCreditType,
    credits: credits.filter(
      (credit) => credit.config.creditType === groupCreditType
    ),
  }));
};

const pluck = (key) => (obj) => obj && obj[key];

const Disclose = ({ header, children }) => {
  const [on, set] = useState(false);
  return (
    <div>
      <div onClick={(_) => set(!on)}>{header}</div>
      {on && children}
    </div>
  );
};
