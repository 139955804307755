import React, { useState } from "react";
import firebase from "firebase";

firebase.initializeApp({
  apiKey: "AIzaSyAmSyRN53Vh0kScGb8fsXH2AW7twK1Edpg",
  authDomain: "bdp-web-1cee3.firebaseapp.com",
  databaseURL: "https://bdp-web-1cee3.firebaseio.com",
  projectId: "bdp-web-1cee3",
  storageBucket: "bdp-web-1cee3.appspot.com",
  messagingSenderId: "176345588040",
  appId: "1:176345588040:web:69a900828a6026dea8d37d",
  measurementId: "G-62QD2MF0KD",
});

firebase.analytics();

firebase.database.enableLogging(true);

window.___logout = firebase.auth().signOut;

export const useUser = () => {
  const [user, setUser] = useState(undefined);
  const [isLoggingIn, setLoggingIn] = useState(false);
  const [errorLoggingIn, setErrorLoggingIn] = useState(false);

  // setup login detection
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      setUser(user);
    } else {
      setUser(undefined);
    }
  });

  const login = (email, password) => {
    setLoggingIn(true);
    setErrorLoggingIn(false);
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((...results) => {
        console.log(results);
        setLoggingIn(false);
      })
      .catch((error) => {
        setErrorLoggingIn(true);
        setLoggingIn(false);
      });
  };

  const logout = (_) => firebase.auth().signOut();

  return { user, login, isLoggingIn, errorLoggingIn, logout };
};

export const Login = ({ onSubmit, disabled, error }) => {
  const [credentials, setCredentials] = useState({
    email: undefined,
    password: undefined,
  });
  return (
    <div className="d-flex w-100 text-center justify-content-center">
      <div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            onSubmit(credentials);
          }}
        >
          {error && (
            <div className="alert alert-danger py-4 ">
              Erro ao tentar entrar. Tente outra vez.
            </div>
          )}
          <div className="form-group">
            <small id="emailHelp" className="form-text text-muted text-center">
              Faça login para utilizar a aplicação
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Email</label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              disabled={disabled}
              onChange={(event) =>
                setCredentials({ ...credentials, email: event.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputPassword1">Password</label>
            <input
              type="password"
              className="form-control"
              id="exampleInputPassword1"
              disabled={disabled}
              onChange={(event) =>
                setCredentials({ ...credentials, password: event.target.value })
              }
            />
          </div>
          <button type="submit" className="btn btn-primary" disabled={disabled}>
            Entrar
          </button>
        </form>
      </div>
    </div>
  );
};
