import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";

export const FileDrop = ({ onFileLoad }) => {
  const [{ loading, loaded }, set] = useState({
    loading: false,
    loaded: false,
  });
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    set({ loading: true, loaded: false });
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () =>
        onFileLoad &&
        onFileLoad(file, reader.result).then(() =>
          set({ loading: false, loaded: true })
        );
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="w-100">
      <div className="card" {...getRootProps()}>
        <div className="card-body text-center">
          <input {...getInputProps()} />
          {loading && (
            <p>
              <span className="alert alert-info">A carregar ...</span>
            </p>
          )}
          {loaded && (
            <p>
              <span className="alert alert-success">Acabou de carregar.</span>
            </p>
          )}
          {isDragActive ? (
            <p>Arrastar os ficheiros para aqui ...</p>
          ) : (
            !loading && (
              <>
                <p>
                  <b>Arraste</b> os ficheiros para aqui para <b>carregar</b>
                </p>
                <p>
                  ou <b>clique aqui</b> para seleccionar ficheiros.
                </p>
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};
